import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { api } from '../services/api.config';
import './EmployeeDetail.css'; // Gaya CSS untuk mempercantik tampilan
import companyLogo from '../assets/image/Logo Perusahaan.png';

const EmployeeDetail = () => {
  const { id } = useParams();
  const [employee, setEmployee] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    setError(null);

    api.get(`/users/username/${id}`)
      .then(response => {
        setTimeout(() => {
          if (response.data.data) {
            setEmployee(response.data.data);
          } else {
            setError('User not found');
          }
          setLoading(false);
        }, 1200);
      })
      .catch(error => {
        console.error("Error fetching user:", error.message);
        setError('User not found');
        setLoading(false);
      });
  }, [id]);

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          textAlign: 'center',
        }}
      >
        <p>Loading...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          textAlign: 'center',
        }}
      >
        <img src={companyLogo} alt="Company Logo" className="company-logo" />
        Employee not found!
      </div>
    );
  }

  const handleLoginClick = () => {
    if (employee.isActive) {
      window.location.href = '/home';
    }
  };

  return (
    <div className="employee-detail-container">
      <div className="company-info">
        <img src={companyLogo} alt="Company Logo" className="company-logo" />
        <h1 className="company-name">PT HASIL KARYA HASIL KARYA</h1>
      </div>

      <div className="employee-card">
        <div className={`employee-status-indicator ${employee.isActive ? 'active' : 'inactive'}`}>
          <span className="status-circle"></span>
          <span className="status-text">{employee.isActive ? 'active' : 'inactive'}</span>
        </div>
        <div className="employee-photo-container">
          <img src={employee.photo} alt="Employee Profile" className="employee-photo" />
        </div>
        <div className="employee-details">
          <h2 className="employee-name">{employee.name}</h2>
          <p className="employee-id">ID: <strong>{employee.employeeDetails.employeeId}</strong></p>
          <p className="employee-division">Division: <strong>{employee.employeeDetails.division}</strong></p>
          <p className="employee-position">Position: <strong>{employee.employeeDetails.position}</strong></p>
        </div>

        <button
          className="employee-login-button"
          onClick={handleLoginClick}
          disabled
        >
          Login to System
        </button>
      </div>
    </div>
  );
};

export default EmployeeDetail;
