import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const EmployeeCreate = () => {
  const [formData, setFormData] = useState({
    id: '',
    name: '',
    division: '',
    position: '',
    status: '',
    photo: null,
  });
  const [photoPreview, setPhotoPreview] = useState(null); // Add state for photo preview URL

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    setFormData({ ...formData, photo: file });
    if (file) {
      setPhotoPreview(URL.createObjectURL(file)); // Generate preview URL
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const url = `${process.env.REACT_APP_SSO_URL}/employee/${formData.id}`;

    navigate('/employee/id-card', { 
      state: { data: formData, qrUrl: url, photoUrl: photoPreview } 
    });
  };

  return (
    <div>
      <div style={styles.container}>
        <h2 style={styles.title}>Add New Employee</h2>
        <form onSubmit={handleSubmit} style={styles.form}>
          <div style={styles.formGroup}>
            <label style={styles.label}>ID:</label>
            <input
              type="text"
              name="id"
              value={formData.id}
              onChange={handleChange}
              style={styles.input}
              required
            />
          </div>
          <div style={styles.formGroup}>
            <label style={styles.label}>Name:</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              style={styles.input}
              required
            />
          </div>
          <div style={styles.formGroup}>
            <label style={styles.label}>Division/Department:</label>
            <input
              type="text"
              name="division"
              value={formData.division}
              onChange={handleChange}
              style={styles.input}
              required
            />
          </div>
          <div style={styles.formGroup}>
            <label style={styles.label}>Position:</label>
            <input
              type="text"
              name="position"
              value={formData.position}
              onChange={handleChange}
              style={styles.input}
              required
            />
          </div>
          <div style={styles.formGroup}>
            <label style={styles.label}>Status:</label>
            <select
              name="status"
              value={formData.status}
              onChange={handleChange}
              style={styles.select}
              required
            >
              <option value="">Select status</option>
              <option value="harian">Karyawan Harian Lepas</option>
              <option value="kontrak">Kontrak</option>
              <option value="tetap">Tetap</option>
            </select>
          </div>
          <div style={styles.formGroup}>
            <label style={styles.label}>Photo:</label>
            <input type="file" name="photo" onChange={handlePhotoChange} style={styles.inputFile} />
          </div>
          {photoPreview && <img src={photoPreview} alt="Employee Preview" style={{ width: 100, height: 100, objectFit: 'cover', objectPosition: 'center', marginTop: 10 }} />}
          <button type="submit" style={styles.button}>Add Employee</button>
        </form>
      </div>
    </div>
  );
};

const styles = {
  container: {
    maxWidth: '400px',
    margin: '20px auto',
    padding: '20px',
    backgroundColor: '#f7f7f7',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    fontFamily: 'Arial, sans-serif',
  },
  title: {
    textAlign: 'center',
    color: '#333',
    marginBottom: '20px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  formGroup: {
    marginBottom: '15px',
  },
  label: {
    display: 'block',
    marginBottom: '5px',
    color: '#333',
    fontWeight: 'bold',
  },
  input: {
    width: '94%',
    padding: '10px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    outline: 'none',
    transition: 'border-color 0.2s',
  },
  inputFile: {
    border: 'none',
  },
  inputFocused: {
    borderColor: '#007bff',
  },
  select: {
    width: '100%',
    padding: '10px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    outline: 'none',
    backgroundColor: '#fff',
    cursor: 'pointer',
  },
  button: {
    padding: '10px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: 'bold',
    transition: 'background-color 0.3s',
    marginTop: '10px',
  },
};

export default EmployeeCreate;
