import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import EmployeeDetail from './pages/EmployeeDetail';
import Login from './pages/Login';
import Home from './pages/Home';
import ProtectedRoute from './routes/ProtectedRoute'; // Impor komponen ProtectedRoute
import NotFound from './pages/NotFound';
import EmployeeCreate from './pages/EmployeeCreate';
import EmployeeIdCard from './pages/EmployeeIdCard';

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/employee/:id" element={<EmployeeDetail />} />
                <Route path="/employee/id-card" element={<EmployeeIdCard />} />
                <Route path='/employee/create' element={<EmployeeCreate />} /> 
                <Route 
                    path="/home" 
                    element={
                        <ProtectedRoute>
                            <Home />
                        </ProtectedRoute>
                    } 
                />
                <Route path="*" element={<NotFound />} /> {/* Rute untuk 404 */}
            </Routes>
        </Router>
    );
}

export default App;
