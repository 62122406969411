import html2canvas from 'html2canvas-pro';
import React, { useRef } from 'react';
import QRCode from 'react-qr-code';
import { useLocation } from 'react-router-dom';

const EmployeeIdCard = () => {
  const location = useLocation();
  const { data, qrUrl, photoUrl } = location.state || {};  // Destructure photoUrl
  const cardRef = useRef();

  const exportAsImage = () => {
    html2canvas(cardRef.current).then((canvas) => {
      const dataURL = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.href = dataURL;
      link.download = 'EmployeeIDCard.png';
      link.click();
    });
  };

  return (
    <div style={styles.container}>
        <div ref={cardRef} style={styles.cardContainer}>
            {photoUrl && (
                <div style={styles.photoContainer}>
                    <img src={photoUrl} alt="Employee" style={styles.photo} />
                </div>
            )}
            <h2 style={styles.title}>Employee ID Card</h2>
            <div style={styles.infoContainer}>
                <p style={styles.infoText}><strong>ID:</strong> {data?.id}</p>
                <p style={styles.infoText}><strong>Name:</strong> {data?.name}</p>
                <p style={styles.infoText}><strong>Division:</strong> {data?.division}</p>
                <p style={styles.infoText}><strong>Position:</strong> {data?.position}</p>
                <p style={styles.infoText}><strong>Status:</strong> {data?.status}</p>
            </div>
            <div style={styles.qrContainer}>
                <QRCode
                size={128}
                style={{ width: "100%", height: "auto" }}
                value={qrUrl}
                viewBox={`0 0 256 256`}
                />
            </div>
        </div>
        <button onClick={exportAsImage} style={styles.button}>Export as PNG</button>
    </div>
  );
};

export default EmployeeIdCard;

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        padding: '20px',
        alignItems: 'center',
        height: '100vh',       // Full viewport height to vertically center
        backgroundColor: '#f0f0f5', // Light background for better contrast
    },
    cardContainer: {
        width: '300px',
        border: '2px solid #007bff',
        borderRadius: '15px',
        padding: '20px',
        backgroundColor: '#fff',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        textAlign: 'center',
        fontFamily: 'Arial, sans-serif',
        color: '#333',
    },
    photoContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '15px',
    },
    photo: {
        width: '150px',
        height: '150px',
        objectFit: 'cover',
        objectPosition: 'center',
        borderRadius: '50%',
        border: '3px solid #007bff',
    },
    title: {
        fontSize: '1.5em',
        marginBottom: '10px',
        color: '#007bff',
    },
    infoContainer: {
        marginBottom: '20px',
        textAlign: 'left',
    },
    infoText: {
        fontSize: '1em',
        margin: '5px 0',
    },
    qrContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '15px',
    },
};
